// Format fuer PJK-Texte (Markdown mit gewissen Konventionen)
(function(){

module.exports = Constructor;

// Klassen-Variable!
// true:  <!-- page:17 -->
// false: [17]
Constructor.SEITENZAHLEN_ALS_KOMMENTAR = false;


function Constructor(content) {

    // Array mit Textzeilen mit irgendwelchen Parser-Fehlern
    this.parserFehler = [];

    // Metadaten. {id:..., datum:..., titel:..., quelle:..., src:..., ...}
    // source: z.B. "aus xy Verlag ABC, 1965"
    // src: z.B. "SchmiedlCD"
    this.metadata = {};
    
    // die einzelnen Bloecke mit folgenden Varianten:
    // {type:ueberschrift,  text:'die Ueberschrift', level:1..3} (Konvention: erster Block: level 1 mit Seitentitel, dann nur level 2+3)
    // {type:origseitenzahl,nr:17}
    // {type:kommentar,     text:'string1\nstring2\nstring3', einzeilig=true/false
    // {type:line}
    // {type:text,          parts:[...]}
    // {type:vorformatiert, text:...}
    // {type:zitat, text:... }
    // {type:spiegelstriche, zeilen:[string1,string2,string3]}
    // wobei die parts eines Textblockes folgendes Format haben:
    // {type:standard,   text:...}
    // {type:fett,       text:...}
    // {type:kursiv,     text:...}
    // {type:fettKursiv, text:...}
    // {type:zeilenumbruch, subtype:hart/orig}
    // {type:fussnote,nr:1,text:...}
    this.blocks = [];
    
    const that = this;

    // Content parsen
    const zeilenData = zeilenKlassifizieren(content.replace(/\r/g,'')); // [{zeile:..., type:... }]   
    const zeilenDataGruppiert = zeilenGruppieren(zeilenData); // [{type:..., zeilen:[zeile]}]
    this.blocks = zeilenDataGruppiert2bloecke(zeilenDataGruppiert, this.metadata, this.parserFehler);

    // Validierungen
    if (content)
        validate(that);
};

// ueber Zeilen iterieren und Typen zuordnen
// content   Dokument-Inhalt
// return    [{zeile:..., type:... }]   
//      mit type: leerzeile      Leerzeile
//                #header        Zeile beginnt mit "# ", "## ", ...
//                =line          ====
//                -line          ----
//                tab4           um >= 4 Leerzeichen eingerueckt
//                *spiegelstrich Zeile beginnt mit "* "
//                <!---->        <!-- ... -->
//                <!--           <!-- ...
//                -->            ... -->
//                <!--*-->       irgendwelche Zeilen zwischen <!-- und -->
//                >line          "> blockquote" oder ">> ...", ...
//                []             Originalseitenzahl
//                [^]:           Fussnotentext
//                standard       normale Textzeilen
function zeilenKlassifizieren(content) {
    const ret = [];
    if (!content) return [];
    const zeilen = content.trim().split('\n');
    zeilen.forEach(function(zeile) {
        let type = 'standard';
        const char0 = zeile[0];
        switch(char0) {
            case '#':
                if (zeile.match(/^\#\#?\#?\#?\#?\#? /)) type = '#header';
                break;
            case '=':
                if (zeile.match(/^\=*$/))       type = '=line';
                break;
            case '-':
                if (zeile.indexOf('-->')>=0)     type = '-->';
                else if (zeile.match(/^\-\-\-*$/))   type = '-line';
                break;
            case ' ':
                if (zeile.indexOf('    ')==0)   type = 'tab4';
                break;
            case '*': 
                if (zeile.indexOf('* ')==0)     type = '*spiegelstrich';
                break;
            case '<': 
                if (zeile.indexOf('<!--')==0) {
                    if (zeile.indexOf('-->')>=0) type = '<!---->';
                    else                         type = '<!--';
                }
                break;
            case '<': 
                if (zeile.match(/^\<\<* /)) type = '>line';
                break;
            case '[': 
                if      (zeile.match(/^\[\^[0-9][0-9]*\]\:/g)) type = '[^]:';
                else if (zeile.match(/^\[[0-9][0-9]*\]$/g))    type = '[]';
                break;
            default:
                if (! zeile.trim().length)              type = 'leerzeile';
                else                                    type = 'standard';
                break;
        }
        ret.push({
            type: type,
            zeile: zeile
        });
    });
//console.log(ret);
    return ret;
}

// Zeilen zu Bloecken gruppieren
// zeilenData Rueckgabe von zeilenKlassifizieren()
// return     [{type:..., zeilen:[zeile]}]
//     mit  type=#header,=line,*spiegelstrich,...
function zeilenGruppieren(zeilenData) {
    const ret = [];
    let lastLineTyp = '';
    let block = null;
    let withinComment = false;
    block = blockAbschliessen(block, ret);
    for (let lineNr=0; lineNr<zeilenData.length; lineNr++) {
        const line       = zeilenData[lineNr].zeile;
        let curLineTyp = zeilenData[lineNr].type;
        if (withinComment && (curLineTyp != '-->')) 
            curLineTyp = '<!--*-->';
        switch(curLineTyp) {
            case 'leerzeile':     block = handleGrpLeerzeile(line, lastLineTyp, ret, block);    break;
            case '#header':       block = handleGrpHeaderPrefix(line, lastLineTyp, ret, block);    break;
            case '=line':
            case '-line':         block = handleGrpHeaderUnderline(line, lastLineTyp, ret, block, curLineTyp);    break;
            case 'tab4':          block = handleGrpTab4(line, lastLineTyp, ret, block);    break;
            case '*spiegelstrich':block = handleGrpSpiegelstrich(line, lastLineTyp, ret, block);    break;
            case '<!---->':       block = handleGrpCommentAufZu(line, lastLineTyp, ret, block);    break;
            case '<!--':          block = handleGrpCommentAuf(line, lastLineTyp, ret, block);  
                                  withinComment = true;                                       break;
            case '-->':           block = handleGrpCommentZu(line, lastLineTyp, ret, block);  
                                  withinComment = false;                                       break;
            case '<!--*-->':      block = handleGrpWithinComment(line, lastLineTyp, ret, block);   break;
            case '>line':         block = handleGrpQuoteBlock(line, lastLineTyp, ret, block);    break;
            case '[^]:':          block = handleGrpFussnote(line, lastLineTyp, ret, block);    break;
            case '[]':            block = handleGrpOrigPage(line, lastLineTyp, ret, block);    break;
            case 'standard':
            default:              block = handleGrpText(line, lastLineTyp, ret, block);    break;
        }
        lastLineTyp = curLineTyp;
    }
    if (!ret.length || (ret[ret.length-1]!=block))
        ret.push(block);
//console.log(ret);
    return ret;
}

// return der aktuelle Block (es wurde ggf. ein neuer angelegt)
function handleGrpLeerzeile(line, lastLineTyp, arrayToAdd, block) {
    switch(lastLineTyp) {
       case '':           // erster Block - wird hier einfach ignoriert
            block.type = 'leerzeile'; // kein break!
        case 'leerzeile':  // zwei leerzeilen gelten, wie eine - ignorieren
        case '<!---->':    // hinter Kommentaren haben Leerzeilen keine Bedeutung
        case '-->':            
            return block;
        case '<!--':
            // Leerzeile zum Kommentar Block dazu
            block.zeilen.push(line);
            return block;
//        case 'standard':
//        case '>line':
//        case '#header': 
//        case '=line':
//        case '-line':
//        case 'tab4':    
//        case '*spiegelstrich': 
        default:   // diese Bloecke werden durch Leerzeilen abgeschlossen
            block = blockAbschliessen(block, arrayToAdd);
            block.type = 'leerzeile';
            return block;
    }
}

// return aktueller Block
function handleGrpHeaderPrefix(line, lastLineTyp, arrayToAdd, block) {
    switch(lastLineTyp) {
        case '':
            block.type = '#header';
            block.zeilen.push(line);
            return block;
        case '<!--':
            // zum Kommentar Block dazu
            block.zeilen.push(line);
            return block;
//        case 'standard':
//        case 'leerzeile':
//        case '<!---->':
//        case '-->':            
//        case '>line':
//        case '#header': 
//        case '=line':          
//        case '-line':          
//        case 'tab4':    
//        case '*spiegelstrich': 
        default:
            block = blockAbschliessen(block, arrayToAdd);
            block.type = '#header';
            block.zeilen.push(line);
            return block;
    }
}

// return           aktueller Block
function handleGrpHeaderUnderline(line, lastLineTyp, arrayToAdd, block, curLineType) {
    if (lastLineTyp=='standard') {
        block.type = curLineType;
        block.zeilen.push(line);
        return block;
    } else {
        block = blockAbschliessen(block, arrayToAdd);
        block.type = curLineType;
        return block;
    }
}

// return aktueller Block
function handleGrpTab4(line, lastLineTyp, arrayToAdd, block) {
    switch(lastLineTyp) {
        case '':
            block.type = 'tab4';
            block.zeilen.push(line);
            return block;
        case '>line': // Block geht weiter
        case '*spiegelstrich': 
        case 'tab4':    
        case '<!--':
            block.zeilen.push(line);
            return block;
//        case 'standard':
//        case 'leerzeile':
//        case '<!---->':
//        case '-->':            
//        case '#header': 
//        case '=line':          
//        case '-line':          
        default:
            block = blockAbschliessen(block, arrayToAdd);
            block.type = 'tab4';
            block.zeilen.push(line);
            return block;
    }
}

// return aktueller Block
function handleGrpSpiegelstrich(line, lastLineTyp, arrayToAdd, block) {
    switch(lastLineTyp) {
        case '':
            block.type = '*spiegelstrich';
            block.zeilen.push(line);
            return block;
        case '*spiegelstrich':  // Block geht weiter
        case '<!--':
            block.zeilen.push(line);
            return block;
//        case 'standard':
//        case 'leerzeile':
//        case '<!---->':
//        case '-->':            
//        case '#header': 
//        case '=line':          
//        case '-line':          
//        case '>line':
//        case 'tab4':    
        default:
            block = blockAbschliessen(block, arrayToAdd);
            block.type = '*spiegelstrich';
            block.zeilen.push(line);
            return block;
    }
}

// return aktueller Block
function handleGrpCommentAufZu(line, lastLineTyp, arrayToAdd, block) {
    switch(lastLineTyp) {
        case '':
            block.type = '<!---->';
            block.zeilen.push(line);
            return block;        
        case '-->':  // Block geht weiter
            block.zeilen.push(line);
            return block;
        case '<!---->': // fuer jede Zeile einen eigenen Block, denn es sind z.B. Seitenzahlen
        case '<!--':  // eigentlich syntaktisch nicht sauber, aber wie fangen trotzdem einen neuen Seitenzahlenblock an
        default:
            // neuen Block anfangen
            block = blockAbschliessen(block, arrayToAdd);
            block.type = '<!---->';
            block.zeilen.push(line);
            return block;
    }
}

// return aktueller Block
function handleGrpCommentAuf(line, lastLineTyp, arrayToAdd, block) {
    switch(lastLineTyp) {
        case '':
            block.type = '<!--';
            block.zeilen.push(line);
            return block;        
        case '<!---->':
        case '-->':  // Block geht weiter
        case '<!--':
            block.zeilen.push(line);
            return block;
        default:
            // neuen Block anfangen
            block = blockAbschliessen(block, arrayToAdd);
            block.type = '<!--';
            block.zeilen.push(line);
            return block;
    }
}

// return aktueller Block
function handleGrpCommentZu(line, lastLineTyp, arrayToAdd, block) {
    switch(lastLineTyp) {
        case '':
            block.type = '-->';
            block.zeilen.push(line);
            return block;                
        case '<!---->': // Block geht weiter
        case '-->':
        case '<!--':
            block.zeilen.push(line);
            return block;
        case '<!--*-->': // das ist das typische
        default:
            // neuen Block anfangen
            block = blockAbschliessen(block, arrayToAdd);
            block.type = '-->';
            block.zeilen.push(line);
            return block;
    }
}

// return aktueller Block
function handleGrpWithinComment(line, lastLineTyp, arrayToAdd, block) {
    block.zeilen.push(line);
    return block;
}

// return aktueller Block
function handleGrpQuoteBlock(line, lastLineTyp, arrayToAdd, block) {
    switch(lastLineTyp) {
        case '':
            block.type = '>line';
            block.zeilen.push(line);
            return block;
        case '>line': // Block geht weiter
        case 'tab4':    
        case '<!--':
            block.zeilen.push(line);
            return block;
//        case 'standard':
//        case 'leerzeile':
//        case '<!---->':
//        case '-->':            
//        case '#header': 
//        case '=line':          
//        case '-line':
//        case '*spiegelstrich':  
        default:
            block = blockAbschliessen(block, arrayToAdd);
            block.type = '>line';
            block.zeilen.push(line);
    }
}

// return aktueller Block
function handleGrpFussnote(line, lastLineTyp, arrayToAdd, block, footnoteContainer) {
    switch(lastLineTyp) {
        case '':
            // erster Block
            block.type = '[^]:';
            block.zeilen.push(line);
            return block;
        default:
            // neuen Block anfangen
            block = blockAbschliessen(block, arrayToAdd);
            block.type = '[^]:';
            block.zeilen.push(line);
            return block;
    }
}

// return aktueller Block
function handleGrpOrigPage(line, lastLineTyp, arrayToAdd, block, footnoteContainer) {
    switch(lastLineTyp) {
        case '':
            // erster Block
            block.type = '[]';
            block.zeilen.push(line);
            return block;
        default:
            // neuen Block anfangen
            block = blockAbschliessen(block, arrayToAdd);
            block.type = '[]';
            block.zeilen.push(line);
            return block;
    }
}

// return aktueller Block
function handleGrpText(line, lastLineTyp, arrayToAdd, block) {
    switch(lastLineTyp) {
        case '':
            // erster Block
            block.type = 'standard';
            block.zeilen.push(line);
            return block;
        case 'standard': 
        case '<!--':           
        case '>line':
            // Vorgaengerblock weiterbauen
            block.zeilen.push(line);
            return block;
//        case 'leerzeile':  
//        case '#header': 
//        case '=line':          
//        case '-line':          
//        case 'tab4':    
//        case '*spiegelstrich': 
//        case '<!---->':        
//        case '-->':            
        default:
            // neuen Block anfangen
            block = blockAbschliessen(block, arrayToAdd);
            block.type = 'standard';
            block.zeilen.push(line);
            return block;
    }
}

function blockAbschliessen(block, arrayToAdd) {
    if (block)
        arrayToAdd.push(block);
    return {
        type: '',
        zeilen:[]
    };
}


// Gruppierte Zeilen zu Bloecken zusammenfassen
// zeilenDataGruppiert Rueckgabe von zeilenGruppieren()
// metadata            Container, in welchen die Metadaten eingetragen werden
// return              Bloecke im Format fuer this.blocks
function zeilenDataGruppiert2bloecke(zeilenDataGruppiert, metadata, parserFehler) {
    const that = this;
    const blocks = [];
    const footnoteContainer = {}; // {nr:text}
    zeilenDataGruppiert.forEach(function(zeilenBlock) {
        switch(zeilenBlock.type) {
            case 'leerzeile': break; // nichts tun, einfach neuer Absatz
            case '#header':
            case '-line':
            case '=line': createUeberschriftBlock(zeilenBlock, zeilenBlock.type, blocks); break;
            case 'tab4':
            case '>line': createVorformatiertBlock(zeilenBlock, zeilenBlock.type, blocks); break;    
            case '*spiegelstrich': createSpiegelstrichBlock(zeilenBlock, zeilenBlock.type, blocks); break;
            case '<!---->':
            case '<!--':  // kommt vermutlich nicht vor, da der Block weitergefuellt wurde bis zum -->
            case '-->': createKommentarBlock(zeilenBlock, zeilenBlock.type, blocks, metadata); break;
            case '[]': createOrigPageBlock(zeilenBlock, blocks); break;
            case '[^]:': storeFootnote(zeilenBlock, footnoteContainer); break;
            case 'standard': createTextBlock(zeilenBlock, blocks); break;              
        }
    });
    // jetzt die Fussnotentexte bei den Textbloecken eintragen
    blocks.forEach(function(block) {
        if (block.type=='text')
            block.parts.forEach(function(part) {
                if (part.type=='fussnote') {
                    const fussnotenText = footnoteContainer[part.nr];
                    if (fussnotenText===undefined) parserFehler.push('Fussnote '+part.nr+' hat keinen Text');
                    else                           part.text = fussnotenText;
                }
            });
    });
//console.log(blocks);
    return blocks;
}

// zeilentyp #header,-line,=line
// blockContainer Referenz auf this.blocks
function createUeberschriftBlock(zeilenBlock, zeilentyp, blockContainer) {
    if (!zeilenBlock.zeilen.length) {
        if (zeilentyp=='-line')
            blockContainer.push({type: 'linie'});
        else
            throw 'was machen wir mit ======== '+zeilentyp;
        return;
    }

    let level = 0;
    switch(zeilentyp) {
        case '#header':
            while (zeilenBlock.zeilen[0][0] == '#') {
                zeilenBlock.zeilen[0] = zeilenBlock.zeilen[0].substr(1).trim();
                level++;
            }
            break;
        case '=line':
        case '-line':
            zeilenBlock.zeilen.pop(); // ---- weg
            level = (zeilentyp == '=line') ? 1 : 2;
            break;
    }
    blockContainer.push({
        type: 'ueberschrift',
        text: zeilenBlock.zeilen.join(' '),
        level: level
    });
}

// zeilentyp >line,tab4
function createVorformatiertBlock(zeilenBlock, zeilentyp, blockContainer) {
    for (let i=0; i< zeilenBlock.zeilen.length; i++) {
        switch(zeilentyp) {
            case '>line':
                let einrueck = '';
                while (zeilenBlock.zeilen[i] == '>') {
                    zeilenBlock.zeilen[i] = zeilenBlock.zeilen[i].substr(1);
                    einrueck += ' ';
                }
                zeilenBlock.zeilen[i] = einrueck.substr(1) + zeilenBlock.zeilen[i];
                break;
            case 'tab4':
                zeilenBlock.zeilen[i] = zeilenBlock.zeilen[i].substr(4);
                break;
        }
    }
    blockContainer.push({
        type: 'vorformatiert',
        text: zeilenBlock.zeilen.join('\n')
    });
}

function createSpiegelstrichBlock(zeilenBlock, zeilentyp, blockContainer) {
    for (let i=0; i< zeilenBlock.zeilen.length; i++)
        zeilenBlock.zeilen[i] = zeilenBlock.zeilen[i].substr(1).trim();
    blockContainer.push({
        type: 'spiegelstriche',
        zeilen: zeilenBlock.zeilen
    });
}

// <!--,  -->,  <!---->
function createKommentarBlock(zeilenBlock, zeilentyp, blockContainer, metadata) {
    // Originalseitenzahl
    if (Constructor.SEITENZAHLEN_ALS_KOMMENTAR && (zeilentyp == '<!---->')) {
        const zeile = zeilenBlock.zeilen[0].substr(4).replace(/\-\-\>/g,'').trim();
        if (zeile.indexOf('page:')==0) {
            const nr = 1*zeile.substr(5).trim();
            if (!isNaN(nr)) {
                blockContainer.push({
                    nr:  nr,
                    type:'origseitenzahl'
                });
                return;
            }
        }
    }
    // alle Zeilen untersuchen
    const kommentarZeilen = [];
    for (let i=0; i< zeilenBlock.zeilen.length; i++) {
        let zeile = zeilenBlock.zeilen[i];
        if (['<!--', '<!---->', '-->'].indexOf(zeile.trim())>=0) continue;
        zeile = zeile.replace(/^\<\!\-\-/g,'') // <!-- und --> weg
                     .replace(/\-\-\>/g,'');
        if (zeile.match(/^\s*[A-Za-z_][A-Za-z0-9_]*\:/g)) {
            // Metadata
            const pos = zeile.indexOf(':');
            const key = zeile.substr(0,pos).trim();
            const value = zeile.substr(pos+1).trim();
            metadata[key.trim()] = value;
        } else {
            // normale Kommentarzeile
            if (zeilentyp == '<!---->')  // <!-- kommentar -->  hier brauchen wir die Leerzeichen nicht
                zeile = zeile.trim();
            kommentarZeilen.push(zeile);
        }
    }
    if (kommentarZeilen.length) {
        blockContainer.push({
            type: 'kommentar',
            einzeilig: (zeilentyp == '<!---->'),
            text: kommentarZeilen.join('\n')
        });
    }
}

function createOrigPageBlock(zeilenBlock, blockContainer) {
    const zeile = zeilenBlock.zeilen[0].trim();
    const nr = 1*zeile.replace('[','').replace(']','');
    blockContainer.push({
        nr:  nr,
        type:'origseitenzahl'
    });
}

function createTextBlock(zeilenBlock, blockContainer) {
    const parts = [];
    let text = zeilenBlock.zeilen.join('\n');
    let curPos = 0;
    let isFett = false;
    let isKursiv = false;
    let ready = false;
    while(! ready) {                                 // pos --v
        const result = sucheNextToken(text, curPos); // ......text_rest --> {text,_,rest}

        if (! result) { // jetzt ist fertig
            parts.push(createTextPart(text.substr(curPos)));
            break;
        }
        curPos += result.first.length + result.token.length;

        if (result.first) // es gelten noch die alten Flags und wir erzeugen fuer result.first einen Part
            parts.push(createTextPart(result.first));
        switch(result.token) {
            // fuer \n und [^] muss ein eigener Part eingebaut werden
            case '\n':
                parts.push({type: 'zeilenumbruch', subtype:'orig'}); break;
            case '  \n':
                parts.push({type: 'zeilenumbruch', subtype:'hart'}); break;
            case '[^]':
                parts.push({type: 'fussnote', nr:result.nr, text:''});
                curPos += (''+result.nr).length; // das Token ist in Wirklichkeit je etwas laenger
                break;
            // fuer _ muessen nur die Flags geaendert werden
            case '_':
            case '*':  isKursiv = ! isKursiv;                        break;
            case '__':
            case '**':
                isFett = ! isFett;                        break;
            case '': // letzter Block im Text
                ready = true;
        };
    }
    if (parts.length)
        blockContainer.push({
            type:  'text',
            parts: parts
        });

    function createTextPart(text) {
        let type = 'standard';
        if (isFett) {
            if (isKursiv)
                type = 'fettKursiv';
            else
                type = 'fett';
        }
        else if (isKursiv)
            type = 'kursiv';
        return {
            type: type,
            text: text
        };
    }

    // sucht nach naechstem Token und schneidet Text dort auseinander
    // Token ist eines von  _ , __ , * , ** , \n , __\n , [...]
    // return  {first: Textteil vor dem Token,
    //          token: das Token
    //          rest:  Textteil nach dem Token,
    function sucheNextToken(text, startIdx) {
        let lastCharWasBackslash = false;
        for (let j=startIdx; j<text.length; j++) {
            const zeichen = text[j];
            lastCharWasBackslash = false;
            switch(zeichen) {
                case '\\':
                    lastCharWasBackslash = true;
                    continue;
                case '\r':
                    continue;
                case '\n':
                    if ((j-startIdx>2)&&(text[j-2]==' ')&&(text[j-1]==' '))
                        return split(j-2, '  \n')
                    else
                        return split(j, '\n');
                case '_':
                    if (lastCharWasBackslash)
                        continue;
                    return split(j, '__')
                        || split(j, '_');
                case '*':
                    if (lastCharWasBackslash)
                        continue;
                    return split(j, '**')
                        || split(j, '*');
                case '[':
                    // aehnliche aber nicht verwendbare Konstruktion in Markdown: 
                    // text[myKey] ....
                    //
                    // [myKey]: https://...
                    // erzeugt einen Link mit der Anzeige "myKey" auf die weiter unten angegebene URL
                    //
                    // wir nehmen:
                    // text[^1]
                    //
                    // [^1]: https://...
                    const match = text.substr(j).match(/^\[\^[0-9][0-9]*\]/g);
                    if (! match) //  Array mit dem einen Element [^1]
                        continue;
                    return split(j, match[0])
                default:
                    continue;
            }
        }
        return {
            first: text.substr(startIdx),
            token: '',
            rest: ''
        }

        function split(splitPos, token) {
            return (text.indexOf(token, splitPos)==splitPos)
                 ? {first: text.substr(startIdx, splitPos-startIdx),
                    token: (token[0]=='[') ? '[^]' : token, // bei Fussnoten lassen wir die Zahl weg, damit oben switch funktioniert
                    nr: (token[0]=='[') ? token.substr(2,token.length-3) : null,
                    rest: text.substr(splitPos+token.length)
                   }
                 : null;  // nur um oben das  ||  zu bedienen, nicht als Rueckgabe von sucheNextToken()
        }
    }
}

function storeFootnote(zeilenBlock, footnoteContainer) {
    const lines = []; // typischerweise nur eine
    let nr = 0;
    zeilenBlock.zeilen.forEach(function(line) {
        const pos = line.indexOf(']:');
        if (pos>=0)
            nr = line.substr(2,pos-2);
        lines.push((pos>=0) ? line.substr(pos+2).trim() : '');
    });
    footnoteContainer[nr]=lines.join('\n');
}

function validate(thatPjkDok) {
    // // erster Block muss Ueberschrift level 1 mit Titel sein
    // if (that.blocks.length) {
    //     const seitentitelBlock = that.blocks[0];
    //     if (  (seitentitelBlock.type!='ueberschrift')
    //         ||(seitentitelBlock.level!=1)
    //         ||(seitentitelBlock.text!=that.metadata.titel)
    //     ) that.parserFehler.push('Erster Block muss Ueberschrift level 1 mit Seitentitel sein');
    // }
};

Constructor.prototype.toString = function() {
    const aRet = [];
    const footnoteLines = [];
    const metadataLines = [];
    for (let key in this.metadata)
        metadataLines.push(key+': '+this.metadata[key]);
    if (metadataLines.length) {
        aRet.push('<!--');
        metadataLines.forEach(function(line) {
            aRet.push(line);
        });
        aRet.push('-->');
        aRet.push('');
    }
    let text = '', prefix = '';
    let prevBlock = {};
    this.blocks.forEach(function(block) {
        switch(block.type) {
            case 'kommentar':
                if (block.einzeilig)
                    aRet.push('<!-- ' + block.text + ' -->');
                else {
                    aRet.push('<!--');
                    aRet.push(block.text);
                    aRet.push('-->');
                }
                break;
            case 'ueberschrift':   
                prefix = '';
                for (let i=0; i<block.level; i++)
                    prefix += '#';
                aRet.push(prefix+' '+block.text);
                break;
            case 'linie':   
                aRet.push('');
                aRet.push('---');
                break;
            case 'origseitenzahl':   
                if (Constructor.SEITENZAHLEN_ALS_KOMMENTAR)
                    aRet.push('<!-- page:'+block.nr+' -->');
                else
                    aRet.push('['+block.nr+']');
                break;
            case 'spiegelstriche':   
                block.zeilen.forEach(function(item) {
                    aRet.push('* '+item);
                });
                break;
            case 'vorformatiert':
                //  if (prevBlock.type=='text') // vorformatiert - da muss Leerzeile davor
                    aRet.push('');
                block.text.split('\n').forEach(function(line) {
                    aRet.push('    '+line);
                });
                break;
            default:
            text:
                if (prevBlock.type=='text')
                    aRet.push('');  // wir sind zwar sparsam mit Leerzeilen, aber zwei Textbloecke (=Absaetze) muessen getrennt werden
                text = '';
                // Bug oder Feature ?
                // _kursiv\nkursiv_  --wird zu-->  [kursiv,origZeilenumbruch,kursiv]  --wird zu-->  _kursiv_\n_kursiv_
                block.parts.forEach(function(part) {
                    switch(part.type) {
                        case 'zeilenumbruch': 
                            if (part.subtype=='orig')
                                text += '\n';
                            else
                                text += '  \n';
                            break;
                        case 'fussnote':   
                            // {type:fussnote,nr:1,text:...}
                            text += '[^' + part.nr + ']';
                            footnoteLines.push('[^' + part.nr + ']: ' + part.text);
                            break;
                        case 'fett': 
                            text += '__'+part.text+'__';
                            break;
                        case 'kursiv': 
                            text += '_'+part.text+'_';
                            break;
                        case 'fettKursiv': 
                            text += '___'+part.text+'___';
                            break;
                        default:
                        case 'standard':
                            text += part.text;
                            break;
                    }
                });
                aRet.push(text);
                break;
        }
        prevBlock = block;
    });
    if (footnoteLines.length) {
        aRet.push('');
        footnoteLines.forEach(function(line) {
            aRet.push(line);
        });
    }
    return aRet.join('\n');
};

})();

