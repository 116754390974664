<!--
this directive can be added to an input element
if an validation error occures, it is displayed below the input field.
Valid values are:
 null,undefined,''   do not display anything
 string              show this string as error
 {...}               ...
-->
<style>
    .validationPopup {
        background-color: #ffcccc;
        color: #000000;
        border: 1px solid #000000;
        padding: 1px;
        display: block;
        z-index: 1042;
    }
</style>
<script>
import Popper from "popper.js";

export default {
    update: function(element, binding) {
        if (binding.value)
            show(element, binding.value);
        else
            hide(element);
    }
};

function show(element, value) {
    if (! element.div4popper) {
        element.div4popper = document.createElement('div');
        element.parentNode.insertBefore(element.div4popper, element.nextSibling); // hinter dem element einfuegen
    }
    element.div4popper.textContent = value;
    element.div4popper.classList.add('validationPopup');
    const popper = new Popper(element, element.div4popper, {});
    // popper.update();
}

function hide(element) {
    if (! element.div4popper)
        return;
    element.parentNode.removeChild(element.div4popper);
    element.div4popper = null;
}
</script>
