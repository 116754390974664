// logic for login/logout, admin-rights, etc.

// Bearer-Token got from server
let sessionToken = '';

// Benutzerdaten: [
//    isAdmin: true/false,
// }
let userData = {
};

// Flag, ob Userdaten schon nachgeladen wurden
let userDataLoaded = false;

// man kann Funktionen registrieren, der beim Ein/Ausloggen aufgerufen wird
// uebergebener Parameter: {userData:..., sessionToken:...  } (wenn sessionToken existiert, ist ein Benutzer angemeldet)
let listener = [];


import SrvServerAccess from './SrvServerAccess';

export default {
    // useLocalStorage  Flag for "remember me"
    // return Promise-->sessionToken
    login: function(email, password, useLocalStorage) {
        sessionToken = '';
        // the bearerToken has been stored in a browser storage.
        // Clean this in order to prevent the session to be restarted by click on F5
        localStorage.clear();
        sessionStorage.clear();
        return new Promise(function(resolve, reject) {
            SrvServerAccess.request('POST','/api/login', {email:email, password:password}).then(
                function(serverResponse) { // {data: {token: token, isAdmin: false}}
                    if (!serverResponse || !serverResponse.data) {
                        reject('no data from server');
                        return;
                    }
                    sessionToken = serverResponse.data.token;
                    userData = serverResponse.data;
                    userDataLoaded = true;
                    callListener();
                    // Usualy all data is lost after a reload (F5 etc.)
                    // There are 2 possibilities to persist data:
                    // window.sessionStorage = {key:value} data are accessible after a reload i.e. by F5
                    //                                     if the browser window is closed, the data are lost
                    // window.localStorage = {key:value}   if you close the browser and will open the html page
                    //                                     at a later time, the data will yet exist there
                    // we use the sessionStorage. If you click the checkbox "remember me", we use localStorage
                    // https://www.epiloge.com/how-to-permanently-save-data-with-vuex--localstorage-in-your-vue-app-c27e91
                    sessionStorage.setItem('session', sessionToken);            
                    if (useLocalStorage)
                        localStorage.setItem('session', sessionToken);            
                    resolve(sessionToken);
                },
                function(error) {
                    reject(error);
                }
            );
        });
    },

    // After F5 oder after Start with "remember me"
    // return Promise-->sessionToken
    reactivateSession: function() {
        sessionToken = sessionStorage['session']; // after F5
        if (!sessionToken)
            sessionToken = localStorage['session']; // from "remember me"
        return new Promise(function(resolve, reject) {
            if (! sessionToken) {
                resolve('');
                return;
            }
            SrvServerAccess.request('GET','/api/me').then(
                function(serverResponse) { // {data: {token: token, isAdmin: false}}
                    userData = serverResponse.data;
                    userDataLoaded = true;
                    callListener();
                    resolve(sessionToken);
                },
                function(error) {
                    reject(error);
                }
            );
        });
    },

    // make a user logout
    // return Promise
    logout: function() {
        return new Promise(function(resolve, reject) {
            // SrvServerAccess.request('GET','/api/logout');    // es gibt keine Logout-Server-Funktion
            userData = {};
            userDataLoaded = false;
            sessionToken = '';
            sessionStorage.setItem('session', '');
            localStorage.setItem('session', '');
            callListener();
            resolve();
        });
    },
    isLoggedIn: function() {
        return sessionToken ? true : false;
    },
    getUserdata: function() {
        return userData;
    },
    necessaryReloadUserData: function() {
        if (! sessionStorage['session'] && ! localStorage['session']) // no Token strored
            return false;
        return (! userDataLoaded);
    },
    addListener: function(newListener) {
        listener.push(newListener);
        callListener(newListener);
    },
    removeListener: function(theListener) {
        listener.splice(listener.indexOf(theListener), 1);
    },
    getSessionToken: function() {
        return sessionToken;
    }
}

function callListener(newListener) {
    if (newListener)
        newListener({userData:userData, sessionToken:sessionToken });
    else 
        listener.forEach(function(curListener) {
            curListener({userData:userData, sessionToken:sessionToken });
        });
}

/*
function switchToLogin() {
    sessionToken = token;
    userData.isAdmin = isAdmin;
    // Clean browser storage in order to prevent the session to be restarted by click on F5
    localStorage.clear();
    sessionStorage.clear();
    // go to the login view
    router.push('/login').catch(function(){});
}
*/
