import { render, staticRenderFns } from "./CmpCtrlEvent.vue?vue&type=template&id=2c6b393e"
import script from "./CmpCtrlEvent.vue?vue&type=script&lang=js"
export * from "./CmpCtrlEvent.vue?vue&type=script&lang=js"
import style0 from "./CmpCtrlEvent.vue?vue&type=style&index=0&id=2c6b393e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../vue-projekte/danielKentenichDb/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports