// Formats a PjkDok as HTML

export default {
    // pjkDok document, to be formated
    // config {
    //     htmlHeader: true: add an HTML-Header (default: false)
    //     style: true: add a stylesheet-Block (default: false)
    //     metadata: true: Metadaten from markdown remaks are rendered (default: false)
    //     snippets: Array with strings to be highlighted by <em>...</em>
    // }
    pjkDok2Html: function(pjkDok, config) {
        const FOOTNOTE_ANCHOR_NAME = 'footnoteanchor';
        
        if (! config) config = {};
        var aRet = [];
        var footnoteIdx = 0;
        var footnoteMap = {}; // footnoteIdx:text
        
        if (config.htmlHeader)
            aRet.push('<html>');
        if (config.style) {
            aRet.push(' <style>');
            aRet.push(' div.PjkDokAsHtmlOrigPageNo {');
            aRet.push('  font-size: 0.8em;');
            aRet.push('  font-weight: 700;');
            aRet.push('  text-align: center;');
            aRet.push(' }');
            aRet.push(' a.PjkDokAsHtmlFootnote {');
            aRet.push('  font-size: 0.7em;');
            aRet.push('  font-weight: 700;');
            aRet.push('  text-decoration: none;');
            aRet.push('  vertical-align:super;');
            aRet.push(' }');
            aRet.push(' table.PjkDokAsHtmlMetadata {');
            aRet.push('  color: #555555;');
            aRet.push('  font-size: 0.8em;');
            aRet.push('  border: 1px solid #555555');
            aRet.push(' }');
            aRet.push(' </style>');
        }
        aRet.push('<div class="PjkDokAsHtml">');
        if (config.metadata) {
            aRet.push(' <table class="PjkDokAsHtmlMetadata">');
            for (var key in pjkDok.metadata)
                aRet.push('  <tr><td>'+key+'</td><td>'+pjkDok.metadata[key]+'</td></tr>');
            aRet.push(' </table>');
        }
        for (var i=0; i<pjkDok.blocks.length; i++) {
            var block = pjkDok.blocks[i];
            switch(block.type) {
                case 'kommentar':
                    break;
                case 'ueberschrift':   
                    var tag = 'h'+block.level;
                    aRet.push(' <'+tag+'>'+encodeText(block.text)+'</'+tag+'>');
                    break;
                case 'origseitenzahl':   
                    aRet.push(' <div class="PjkDokAsHtmlOrigPageNo">'+block.nr+'</div>');
                    break;
                case 'spiegelstriche':   
                    aRet.push(' <ul>');
                    for (var j=0; j<block.zeilen.length; j++)
                        aRet.push('  <li>'+encodeText(block.zeilen[j])+'</li>');
                    aRet.push(' </ul>');
                    break;
                case 'vorformatiert':   
                    aRet.push(' <pre>');
                    aRet.push(encodeText(block.text));
                    aRet.push(' </pre>');
                    break;
                default:
                text:
                    var text = '';
                    for (var j=0; j<block.parts.length; j++) {
                        var part = block.parts[j];
                        switch(part.type) {
                            case 'zeilenumbruch': 
                                if (part.subtype=='hart') text += '</br>';
                                else                      text += '\n';
                                break;
                            case 'fussnote':   
                                // {type:fussnote,nr:1,text:...}
                                footnoteIdx++;
                                text += '<a name="'+FOOTNOTE_ANCHOR_NAME+footnoteIdx+'" class="PjkDokAsHtmlFootnote" href="#'+footnoteIdx+'">'+part.nr+'</a>';
                                footnoteMap[footnoteIdx] = encodeText(part.text);
                                break;
                            case 'fett': 
                                text += '<b>'+encodeText(part.text)+'</b>';
                                break;
                            case 'kursiv': 
                                text += '<i>'+encodeText(part.text)+'</i>';
                                break;
                            case 'fettKursiv': 
                                text += '<i><b>'+encodeText(part.text)+'</b></i>';
                                break;
                            default:
                            case 'standard':
                                text += encodeText(part.text);
                                break;
                        }
                    }
    //                if (config.snippets)
    //                    text = matchTextToAnySnippet(text);
                    aRet.push(' <p>');
                    aRet.push(text);
                    aRet.push(' </p>');
                    break;
            }
        }
        if (footnoteIdx)
            aRet.push('<hr/>');
        for(let i=1; i<=footnoteIdx; i++)
            aRet.push('<a class="PjkDokAsHtmlFootnote" name="'+i+'" href="#'+FOOTNOTE_ANCHOR_NAME+i+'">'+i+'</a> '+footnoteMap[i]+'<br/>');
        if (config.htmlHeader)
            aRet.push('</html>');    
        var sRet = aRet.join('\n');
        if (config.snippets)
            sRet = matchTextToAnySnippet(sRet);
        return sRet;
        
        function encodeText(text) {
            return text.replace(/\&/g,'&amp;').replace(/\>/g,'&gt;').replace(/\</g,'&lt;');
        }
    
        function matchTextToAnySnippet(text) {
            var erledigt = []; // {idx: Flag, ob snippet schon erledigt ist
            var snippetsOhneEm = []; // snippets ohne <em> und </em> und Whitespaces
            for (var i=0; i<config.snippets.length; i++) {
                erledigt.push(false);
                snippetsOhneEm.push(config.snippets[i].replace(/\s/g,'').replace(/\<\/?em\>/g,''));
            }
            var idxOhneWhitespace = 0; // Zeichen im Text, die wirklich zum Vergleich herangezogen werden.
            for (var i=0; i<text.length; i++) {
                var zeichen = text.charAt(i);
                if (zeichen<=' ') continue;
                var alleErledigt = true;
                for (var j=0; j<snippetsOhneEm.length; j++) {
                    if (erledigt[j]) continue;
                    else             alleErledigt = false;
                    if (zeichen!=snippetsOhneEm[j].charAt(0)) continue;
                    var snippetEndPosInText = snippetMatchedAbPosition(snippetsOhneEm[j], i);
                    if (snippetEndPosInText < 0) continue;
                    erledigt[j] = true;
                    text = text.slice(0, i) 
                         + '<em>'
                         + config.snippets[j].replace(/\<\/?em\>/g,'')
                         + '</em>'
                         + text.substr(snippetEndPosInText+1);
                    var textlaenge = 1 + snippetEndPosInText - i;
                    var korrektur = textlaenge - snippetsOhneEm[j].length;  // etwas groesser als 0
                    i -= korrektur;
                }
                idxOhneWhitespace++;
                if (alleErledigt) break;
            }
            return text;
            
            function snippetMatchedAbPosition(snippetOhneEm, idx) {
                var idxInSnippet = 0;
                var idxInText = idx; // zaehlt schneller, als i, weil es Whitespaces ueberspringt)
                var insideHtmlTag = false;
                while(idxInText<text.length) {
                    while(text.charAt(idxInText)<=' ')
                        idxInText++;
                    if (idxInText>=text.length) return -1;
                    var zeichen = text.charAt(idxInText);
                    if (zeichen=='<') insideHtmlTag = true;
                    if (! insideHtmlTag) {
                        if (snippetOhneEm.charAt(idxInSnippet)!=zeichen) return -1;
                        if (idxInSnippet==snippetOhneEm.length-1) return idxInText;
                        idxInSnippet++;
                    }
                    if (zeichen=='>') insideHtmlTag = false;
                    idxInText++;
                }
            }
        }
    }
}
