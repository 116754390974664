// ./global contains some global services.
// This are javascript files which provide some funktionality, which can be used from any other code file.
// This is a Abstraction for any server access.
import {serverUrl} from '@/configuration';
import {logServerCommunication} from '@/configuration';
//const serverUrl = 'https://jk-backend.skola-zivota.net';  // <-- aus configuration.js nehmen

import SrvMessages from './SrvMessages';

import axios from 'axios';
const $http = require('axios');   // ???  <--- ist das doppelt?

const { Buffer } = require('buffer');

let testMode = false;

/*
testMode = true;
import SrvServerAccessMock from './SrvServerAccessMock';
*/

export default {
    // method          POST, GET, ...
    // apiFunction     i.e. '/api/login'
    // postParam       {key:value}
    // noErrorHandling true: make a reject on returned promise in case of error
    //                 false: (default) show error with SrvMessages.error
    request: function(method, apiFunction, postParam, noErrorHandling) {

// einen Fehler provozieren
//if (apiFunction!='/api/me') apiFunction = '/api/error500';

        if(testMode)
            return SrvServerAccessMock.request(method, apiFunction, postParam);
        else {
            const config = {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                useCredentails: true,
                'Access-Control-Allow-Origin': '*',
                crossdomain: true,

// einen Fehler provozieren
//Accept: 'application/json',

                headers: {}
            }
            // usualy the sessionToken is handled by SrvAuthentification
            // but for we do not want circular dependencies, we take it from the browser storage.
            const theSessionToken = sessionStorage['session'] || localStorage['session'];
            if (theSessionToken)
                config.headers.Authorization = 'Bearer '+theSessionToken;
            let promise = null;
            switch(method.toUpperCase()) {
                case 'GET':  promise = $http.get(serverUrl+apiFunction, config); break;
                case 'POST': promise = $http.post(serverUrl+apiFunction, postParam ? postParam : {}, config); break;
                default:     throw 'invalid method: '+method;
            }
            if (noErrorHandling)
                return promise;
            return promise.then(function(result) {
                if (logServerCommunication) {
                    // Daniel needs this for debuggung
                    console.log(serverUrl+apiFunction);
                    if (postParam)
                        console.log(postParam);
                    console.log(result);
                }
                return result;
            },function(error) {
                SrvMessages.error(error);
            });
        }
    },
    requestES: function(searchString) {
        const ES_URL = 'https://v2.kentepedia.de';
        const method = "POST";
//        const apiFunction = '/kentenich/_search'; // nur, falls wir spaeter mal Alternativen haben
const apiFunction = '/daniel/_search';
        const username = 'elasticsearch';
        const password = 'mTa31051949*';
        const config = {
            useCredentails: true,
            crossdomain: true,
            headers: {
                'Authorization': 'Basic '+ Buffer.from(username+':'+password).toString('base64'),
                'Content-Type': 'application/json',
            }
        }
        const postParam = createEsSearchConfig(searchString, '1912-01-01', '1968-12-24', true);
        let promise = null;
        switch(method.toUpperCase()) {
            case 'GET':  promise = $http.get(ES_URL+apiFunction, config); break;
            case 'POST': promise = $http.post(ES_URL+apiFunction, postParam ? postParam : {}, config); break;
            default:     throw 'invalid method: '+method;
        }
        return promise;
    }
}

function createEsSearchConfig(suchstring, fromDate, toDate, withSnippets) {
    const must = [{
//        match: {type: 'text'}
//    },{
        multi_match: {
            query: suchstring,
            type: 'phrase',
            slop: 10,
            fields: ['text', 'title', 'source','_id'],
            operator: 'or'
        }
    }];
    if (fromDate || toDate) {
        const dateQuery = {};
        if (fromDate)
            dateQuery.gte = fromDate;
        if (toDate)
            dateQuery.lte = toDate;
        must.push({
            range: {
                date: dateQuery
            }
        });
    }
    const queryData = {
        query: {
            bool: {
                must: must
            }
        },
        _source: ['src','eventId','versionId']
    };
    if (withSnippets)
        queryData.highlight = {
            // pre_tags: ['<em>'],
            // post_tags: ['</em>'],
            fields: {
                text: {
                    // type: 'plain',
                }
            }
        };
        return queryData;
}