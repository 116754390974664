// Service with some helper functions for UI issues

export default {
    // function may be used as anwer for a click event in order to copy a vaule to the clipboard
    // event the mouse event
    // value the value to be copied into the clipoard
    copyValueToClipboard: function(event, value) {
        event.stopPropagation();

        // only for https
        // navigator.clipboard.writeText(this.config.medium_text_short);

        // Workaround
        const dummyTextarea = document.createElement("textarea");
        document.body.appendChild(dummyTextarea);
        dummyTextarea.value = value;
        dummyTextarea.select();
        document.execCommand('copy');
        document.body.removeChild(dummyTextarea);
    }
}

