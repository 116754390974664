import { render, staticRenderFns } from "./CmpCtrlTranslation.vue?vue&type=template&id=23826da6"
import script from "./CmpCtrlTranslation.vue?vue&type=script&lang=js"
export * from "./CmpCtrlTranslation.vue?vue&type=script&lang=js"
import style0 from "./CmpCtrlTranslation.vue?vue&type=style&index=0&id=23826da6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../vue-projekte/danielKentenichDb/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports