// Service, which gets some snippets and a markdown text
// and will insert some marker into the markdown, in order to highlight the snippets

// snippets Array mit gefundenen Textbloecken
// markdown String mit Markdown Text
// return   Markdown-String mit den zusaetzlich eingebauten Markern START und ENDE
const SrvSnippetMarker = {
    START: '~#MARK|',
    ENDE:  '|MARK#~',
    insertMarker: function(snippets, markdown) {
        // ["Text, zu markieren"] --> ["textzumarkieren"]
        const snippetsPurged = purgeSnippets(snippets);

        // "_Ueberschrift_ text  text" --> "ueberschrifttexttext" 
        const textBuf = [];
        const map     = [];
        createMap(markdown, textBuf, map);

        // snippets suchen
        const positions = findSnippets(snippetsPurged, markdown, textBuf, map);
            // [{idx:...,    Index des Snippets im Array snippetsPurged
            //   start:...,  erste Position des Snippets im orig. Markdown-Text
            //   end:...}]   letzte Position des Snippets im orig. Markdown-Text
            // das ganze sortiert: zuerst die HINTERSTEN, dann die VOREREN

        return insertMarker(markdown, positions);
    }
}

function purgeSnippets(snippets) {
    const ret = [];
    snippets.forEach(function(snippet){
        ret.push(
            snippet.toLowerCase()
                   .replace(/\<\/?em\>/g, '') // oft steht noch ein <em>...</em>  drin
                   .replace(/[^a-z]/g, '')    // nur Buchstaben uebrig lassen
        );
    });
    return ret;
}

// copies all chars [a-z][A-Z] into a char-Array and its positions within original markdown into an int-Array
// markdown:  "abc d,ef" 
// buffer:    to be filled with [a,b,c,d,e,f]
// map:       to be filled with  [0,1,2,4,6,7]
function createMap(markdown, buffer, map) {
    for (let i=0; i<markdown.length; i++) {
        const char = markdown[i].toLowerCase();
        if (((char<'A')||(char>'Z')) && ((char<'a')||(char>'z')))
            continue;
        buffer.push(char);
        map.push(i);
    }
}

// snippetsPurged  siehe purgeSnippets()
// markdown        Original-Markdown-Text
// textBuf         siehe createMap()
// map
// return          [{idx:..., start:..., end:...}]  
function findSnippets(snippetsPurged, markdown, textBuf, map) {
    const ret = [];
    const text = textBuf.join('');
    for (let i=0; i<snippetsPurged.length; i++) {
        const snippet = snippetsPurged[i];
        // in purged Text
        const posStart = text.indexOf(snippet);
        if (posStart<0)
            continue;
        const posEnd = posStart + snippet.length - 1;
        ret.push({
            idx: i,
            start: map[posStart],
            end: map[posEnd]
        });
        ret.sort(function(a,b){
            return b.start - a.start;
        });
    }
    return ret;
}

// markdown  Original Markdown
// positions return of findSnippets()
// return    Markdown with marker
function insertMarker(markdown, positions) {
    positions.forEach(function(record) {
        markdown = markdown.substr(0, record.start)
                 + SrvSnippetMarker.START
                 + markdown.substr(record.start, 1 + record.end - record.start)
                 + SrvSnippetMarker.ENDE
                 + markdown.substr(record.end+1);
    });
    return markdown;
}

export default SrvSnippetMarker;

/*
const snippets = ['<em>Ausschnitte suchen müssen</em>', 'ist ein','Hall'];
const markdown = 'Hallo, das ist ein schöner Text, in welchem wir _Ausschnitte_ suchen müssen. Jetzt kommt das Ende';
console.log(SrvSnippetMarker.insertMarker(snippets, markdown));
*/

