// Formatierungs-Funktionen
export default {
    dateDeutsch2sql: dateDeutsch2sql,
    dateSql2deutsch: dateSql2deutsch
}

// --- Datum/Zeit -----------------------------

// Zeitangabe in sauberes Format bringen
// time   z.B. 8:55
// return z.B. 08:55:00   oder  null
function formatNormalizeTime(time) {
    if (!time) return null;
    if (typeof(time)!="string") time = "" + time;
    var parts   = time.split(":");  
    var stunde  = 1 * parts[0].trim();
    var minute  = (parts.length>1) ? 1 * parts[1].trim() : 0;
    var sekunde = (parts.length>2) ? 1 * parts[2].trim() : 0;
    if (isNaN(stunde )) return null;
    if (isNaN(minute )) return null;
    if (isNaN(sekunde)) return null;
    if ((stunde <0)||(stunde >23)) return null;
    if ((minute <0)||(minute >59)) return null;
    if ((sekunde<0)||(sekunde>59)) return null;
    var ret = (stunde<10) ? "0" : "";
    ret += stunde + ":";
    if (minute<10) ret += "0";
    ret += minute + ":";
    if (sekunde<10) ret += "0";
    ret += sekunde;
    return ret;
}

// deutsches Datum als SQL-Datum formatieren
// kommt auch mit unscharfen Daten zurecht, z.B.   03.1967  oder 1967  oder 67
// date   z.B. 19.3.1967
// return z.B. 1967-03-19 oder null bei ungueltigem Datum
function dateDeutsch2sql(date) {
    if (!date) return null;
    if (typeof(date)!="string") date = "" + date;
    var parts = date.split(".");
    var tag  =0;
    var monat=0;
    var jahr =0;
    switch(parts.length) {
        case 1: jahr  = 1 * parts[0].trim();
                break;
        case 2: monat = 1 * parts[0].trim();
                jahr  = 1 * parts[1].trim();
                break;
        case 3: tag   = 1 * parts[0].trim();
                monat = 1 * parts[1].trim();
                jahr  = 1 * parts[2].trim();
                break;
        default: return null;
    }
    if (isNaN(tag  )) return null;
    if (isNaN(monat)) return null;
    if (isNaN(jahr )) return null;
    if (jahr<1000) {
        var grenzJahr = (new Date()).getFullYear() - 1998; // Folgezahl von heute wird noch zu 2000 gezaehlt, danach zu 1900
        if      (jahr<grenzJahr) jahr += 2000;
        else if (jahr<100      ) jahr += 1900;
    }    
    if ((tag  <   0)||(tag  >  31)) return null;
    if ((monat<   0)||(monat>  12)) return null;
    if ((jahr <1000)||(jahr >2999)) return null;
    var ret = ""+jahr;
    if (!monat) return ret;
    ret += "-";
    if (monat<10) ret += "0";
    ret += monat;
    if (!tag) return ret;
    ret += "-";
    if (tag<10) ret += "0";
    ret += tag;
    return ret;
};

// SQL-Datum ins deutsche Format bringen
// date   z.B. 1967-03-19
// return z.B. 19.03.1967 oder null bei ungueltigem Datum
function dateSql2deutsch(date) {
    if (!date) return null;
    if (typeof(date)!='string') date = '' + date;
    var parts = date.split('-');
    if (parts.length<1) return null;
    var tag  =0;
    var monat=0;
    var jahr  = 1 * parts[0].trim();
    if (parts.length>=2)
        monat = 1 * parts[1].trim();
    if (parts.length>=3)
        tag   = 1 * parts[2].trim();
    if (parts.length>=4) return null;
    if (isNaN(tag  )) return null;
    if (isNaN(monat)) return null;
    if (isNaN(jahr )) return null;
    if ((tag  <   0)||(tag  >  31)) return null;
    if ((monat<   0)||(monat>  12)) return null;
    if ((jahr <1000)||(jahr >2999)) return null;
    var ret = '';
    if (tag && monat)
        ret = ((tag<10) ? '0' : '') + tag + '.';
    if (monat)
        ret += ((monat<10) ? '0' : '') + monat + '.';
    return ret + jahr;
}

// deutsches Datum+Zeit als SQL-Datum formatieren
// date   z.B. 19.3.1967 12:15
// return z.B. 1967-03-19 12:15:00 oder null bei ungueltigem Datum
function datetimeDeutsch2sql(datetime) {
    datetime = datetime.trim();
    if (!datetime) return null;
    var idx = datetime.indexOf(" ");
    if (idx<0) return formatDateDeutsch2sql(datetime);

    var time = datetime.substr(idx+1).trim();
    var time = (time.length>0) ? formatNormalizeTime(time) : "";
    var date = formatDateDeutsch2sql(datetime.substr(0,idx));
    if ((date===null)||(time===null)) return null;
    return (date+" "+time).trim();
}

// SQL-Datum+Zeit ins deutsche Format bringen
// date   z.B. 1967-03-19 12:15:00
// return z.B. 19.03.1967 12:15:00 oder null bei ungueltigem datetime
function datetimeSql2deutsch(datetime) {
    datetime = datetime.trim();
    if (!datetime) return null;
    var idx = datetime.indexOf(" ");
    if (idx<0) return dateSql2deutsch(datetime);
    var time = datetime.substr(idx+1).trim();
    var time = (time.length>0) ? formatNormalizeTime(time) : "";
    var date = dateSql2deutsch(datetime.substr(0,idx));
    if ((date===null)||(time===null)) return null;
    return (date+" "+time).trim();
}
