<!--
this directive can be used in order to make an element only visible,
if a given permission is set within user settings
-->
<script>
import SrvAuthentification from '../global/SrvAuthentification';
export default {
    bind: function(element, binding) {
        // A nice code would be:
        //  <element v-dir-permission="g_EDITORS" 
        // Then we get binding.expression = 'g_EDITORS'
        //             binding.value = undefined
        // But we get the exception:
        //  [Vue warn]: Property or method "g_EDITORS" is not defined on the instance but referenced during render. 
        //  Make sure that this property is reactive, either in the data option, or for class-based components,
        //  by initializing the property. See: https://v2.vuejs.org/v2/guide/reactivity.html#Declaring-Reactive-Properties.
        // So we must write
        //  <element v-dir-permission="'g_EDITORS'" 
        // Then we get binding.expression = 'g_EDITORS'
        //             binding.value = g_EDITORS
        let showElement = false;
        const permissionsString = (binding.value!==undefined)
                                ? binding.value
                                : binding.expression; // Fallback for v-dir-permission="g_EDITORS" 
        const permissions = (permissionsString instanceof Array)
                          ? permissionsString
                          : permissionsString.split(',');
        const userData = SrvAuthentification.getUserdata();
        if (userData)
            for (let i=0; i<permissions.length; i++)
                if (userData.permissions.indexOf(permissions[i])>=0)
                    showElement = true;
        if (!showElement)
            element.style.visibility = 'hidden';
    }
};
</script>
