// the router manages urls and current displayed views:
// usualy the url ends with a hash, i.e. http://127.0.0.1:8080/main or http://myServer/#/main
// then within App.vue in <router-view/> the component for main is shown.
// if you open another view with the menu, then the router will change the url.
import Vue from 'vue'
import VueRouter from 'vue-router'
import SrvAuthentification from '../global/SrvAuthentification';
import CmpViewAbout from '../views/CmpViewAbout.vue';
import CmpSearch from '../views/search/CmpSearch.vue';
import CmpViewEvent from '../views/CmpViewEvent.vue';
import CmpViewVersion from '../views/CmpViewVersion.vue';
import CmpViewMedium from '../views/CmpViewMedium.vue';
import CmpViewLogin from '../views/CmpViewLogin.vue';
import CmpViewRegister from '../views/CmpViewRegister.vue';
import CmpViewForgotPassword from '../views/CmpViewForgotPassword.vue';
import CmpViewResetPassword from '../views/CmpViewResetPassword.vue';
import CmpViewUserSettings from '../views/CmpViewUserSettings.vue';
//import CmpViewAdmin from '../views/CmpViewAdmin.vue';
import UserSetting from '../views/daniel/_UserSetting.vue';


Vue.use(VueRouter)

// this are the keys for the routes and the corresponding components to be shown within <router-view/>
const routes = [{
    // the route / is mapped to /search within beforeEach()
    name: 'default',
    path: '/',
    component: CmpViewLogin,
  },{
    name: 'about',
    path: '/about',
    component: CmpViewAbout,
    meta: {
        requiresAuth: false
    }
  },{
    // main search page
    name: 'search',
    path: '/search:restore?', // restore: flag for restoring last search
//    path: '/search',
    component: CmpSearch,
    meta: {
        requiresAuth: true
    }
  },{
    // show metadata to one event
    name: 'event',
    path: '/event:id', // http:...event:17 --> $route.params.id = ":17"
    component: CmpViewEvent,
    meta: {
        requiresAuth: true
    }
  },{
    // show metadata to one version
    name: 'version',
    path: '/version:idEventAndVersion', //  http:...version:17-3
    component: CmpViewVersion,
    meta: {
        requiresAuth: true
    }
  },{
    // show one medium
    name: 'medium',
    path: '/medium:id',
    component: CmpViewMedium,
    meta: {
        requiresAuth: true
    }
  },{
    // login (and link to register)
    name: 'login',
    path: '/login',
    component: CmpViewLogin
  },{
    // register page
    name: 'register',
    path: '/register',
    component: CmpViewRegister
  },{
    // page to order a link for a password reset
    name: 'forgotPassword',
    path: '/forgotPassword',
    component: CmpViewForgotPassword
  },{
    // page to reset password (link is got from a forgot password email.
    name: 'resetPassword',
    path: '/resetPassword',
    component: CmpViewResetPassword
  },{
    // user settings
    name: 'settings',
    path: '/settings',
    component: CmpViewUserSettings
  },{
    // user management (only for admins)
    name: 'user',
    path: '/user',
    component: UserSetting
//  },{
//    // admin
//    name: 'admin',
//    path: '/admin',
//    component: CmpViewAdmin
}];



const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// this method is called before any change of route
// it will validate the route. 
// If the user is not logged in,
// it will setup route to login view
// https://webomnizz.com/protecting-route-for-authenticated-users-in-vue-js/
router.beforeEach(function(to, from, next) {
    if (SrvAuthentification.necessaryReloadUserData()) {
        SrvAuthentification.reactivateSession().then(
            work,
            function(error) {
console.log(error);
                alert('TODO router-error');
            }
        );
        return;
    } else
        work();

    function work() {
    // for the url contains an invalid route, set to default route /
        if (!to.matched || !to.matched.length) {
            next('/search');
            return;        
        }
        if (to.meta.requiresAuth) {
            if (!SrvAuthentification.isLoggedIn()) {
                next('/login');
                return;
            }
        }
        next();
    }
});

export default router
