import '@babel/polyfill'
import 'mutationobserver-shim'
// This is the start point of the application.

// If you only want to add a custom view,
// look at views/CmpAbout.vue for a simple view
// orr views/CmpLogin.vue for a complex view with server access


// the vue framework
import Vue from 'vue'
import './plugins/bootstrap-vue'

// the main component of the kentenich-db project
// pleas continue reading the sourcecode here
import App from './App.vue'

// the router manages the storing of the program state within the url
import router from './router'

// this is a global place to store the prgram state (=data, to be accesses from several components)
// you can read data. in order to write, you must implement setter functions.
// with this concept it is possible, to get informed, if another component has modified the state.
//import store from './store'


// the css framework
//import './plugins/bootstrap-vue'

// copied from earlier versions of kentenich db.
// maybe we need it for older browsers
// import '@babel/polyfill'


// Markdown editor from  https://github.com/hinesboy/mavonEditor/blob/master/README-EN.md
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

// Mocking the vuex store which is used by Daniel in order to get the sessionToken
Vue.prototype.$store = {
    state: {
        sessionToken: ''
    }
};
Vue.prototype.$confirm = function(text, title, level) {
    return new Promise(function(resolve, reject){
        if (confirm(text)) {
            resolve();
        }
    });
};

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
